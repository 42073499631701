@use 'common'

@font-face
	font-family: 'teuton_fettregular'
	src: url('/fonts/TeutonFett.otf') format('opentype')
	font-weight: normal
	font-style: normal
	font-display: block

@font-face
	font-family: 'teuton_fettbold'
	src: url('/fonts/TeutonFettBold.otf') format('opentype')
	font-weight: normal
	font-style: normal
	font-display: block

@font-face
	font-family: 'teuton_magerregular'
	src: url('/fonts/TeutonMager.otf') format('opentype')
	font-weight: normal
	font-style: normal
	font-display: block

.subterra
	overflow: hidden

html
	scroll-behavior: smooth

:root
	color-scheme: only-light

html,
body
	color: #fff
	padding: 0
	margin: 0
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
	font-family: common.$regular

	h1, h2, h3, h4, h5
		margin: 0 0 .3em 0
		line-height: 1.3em
		-webkit-font-smoothing: antialiased

	h1
		font-family: common.$bold
	h1
		font-size: 85px
		//font-size: 100px
	h2
		//font-size: 50px
		font-size: 45px
		font-family: common.$regular
	article, p
		font-size: 25px
		font-family: common.$mager
		line-height: 1.5em
		margin: 0 0 1em 0

	.section
		padding: 100px 0
		&.grey
			background-color: common.$grey
			color: #000
		&.white
			background-color: #fff
			color: #000

	@media(max-width: 768px)
		h1
			font-size: 60px
		h2
			font-size: 35px
		article, p
			font-size: 20px
		.section
			padding: 50px 0

a
	color: inherit
	text-decoration: none

*
	box-sizing: border-box
